/* You can add global styles to this file, and also import other style files */
:root {
  /* --greenColor: #00bd79; */
  --greenColor: #068466;
  --font: "DM Sans", sans-serif;
}
html,
body {
  max-width: 100% !important;
  height: 100% !important;
  margin: 0px !important;
  font-family: var(--font);
  font-style: normal;
  box-sizing: border-box !important;
}
.cursor {
  cursor: pointer;
}
button:focus {
  outline: 1px dotted;
}
input:focus {
  outline: none;
}
input {
  color: black;
  font: normal normal 500 17px/16px sans-serif;
  letter-spacing: 0px;
}
.main {
  overflow: hidden;
}
/*

====================================================================
                    navbar-css-start
====================================================================
*/
.navbar-light .navbar-nav .nav-link,
.sign-in a {
  font: normal normal 300 18px/38px sans-serif;
  letter-spacing: 0px;
  color: #000000;
}
.navbar-brand {
  font: normal normal 500 30px/30px sans-serif;
  letter-spacing: 0px;
  color: #000000;
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.bg-light {
  background-color: #ffffff !important;
}
.demo_btn button {
  font: normal normal 500 14px/30px sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  background: var(--greenColor);
  padding: 8px 13px;
  border: none;
  border-radius: 8px;
}
.navbar {
  box-shadow: rgba(0, 0, 0, 0.24) 0px -1px 10px;
}
/*

====================================================================
                    navbar-css-end
====================================================================
*/
/*

====================================================================
                    reviewa-css-start
====================================================================
*/
.button_push_section h5 {
  font: normal normal 700 60px/68px sans-serif;
  letter-spacing: 0px;
  color: #000000;
  width: 579px;
}
.button_push_section {
  padding: 17% 0 8% 0;
}
.button_push_section p {
  font: normal normal 100 15px/24px sans-serif;
  letter-spacing: 0px;
  color: #656565;
  width: 379px;
}
.button_push_section h5 span {
  font: normal normal 700 60px/68px sans-serif;
  letter-spacing: 0px;
  color: #076607;
}
.push_button_section {
  background: #f7f9f7;
}
.form_Section input {
  width: 323px;
  height: 45px;
  border: 1px solid #cecece;
  border-radius: 4px;
}
.form_Section_footer input {
  width: 274px;
  height: 45px;
  border: 1px solid #d2d2d2;
  border-radius: 9px;
}

.form_Section input::placeholder {
  color: black;
  padding-left: 10px;
  font: normal normal 500 14px/30px sans-serif;
}
.form_Section_footer input::placeholder {
  color: rgb(138, 138, 138);
  padding-left: 10px;
  font: normal normal 500 14px/30px sans-serif;
}
a {
  text-decoration: none !important;
}
.submit_section a {
  font: normal normal 500 19px / 30px sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 12px 30px;
  border: none;
  border-radius: 8px;
  width: 337px;
  display: block;
  text-align: center;
  background: var(--greenColor);
}
.find_your_industry_section {
  background: #f4fffb;

  background-image: url(".../../assets/images/bg-desing.png");

  height: auto;
  width: auto;
}
.industry_section h4 {
  font: normal normal 600 40px/24px sans-serif;
  letter-spacing: 0px;
  color: #000000;
  text-align: center;
  margin: 34px 0;
}
.people_section_problem h4,
.beat_your_competetion h4 {
  font: normal normal 600 40px/49px sans-serif;
  letter-spacing: 0px;
  color: #000000;
  text-align: center;
  margin: 10px auto 38px;
  width: 594px;
}
.people_section_problem .card-body {
  padding: 48px 15px;
}
.people_section_problem .card img {
  width: 56px;
}
.industry_section button,
.people_section_problem button,
.beat_your_competetion button {
  font: normal normal 200 14px/12px sans-serif;
  letter-spacing: 0px;

  text-align: center;
  color: #c5864b;
  background: #fef4e6;
  border: 1px solid #ebbd95;
  padding: 9px 20px;
  border-radius: 26px;
  align-items: center;
  text-align: center;
  margin: 9px auto;
  display: flex;
}
.industry_section .card,
.people_section_problem .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1.5px solid rgb(0 0 0 / 13%);
  border-radius: 25px;
  width: 270px;
}
.people_section_problem .card {
  border-radius: 25px;
  width: 306px;
}
.people_section_problem p {
  font: normal normal 400 16px/27px sans-serif;
  letter-spacing: 0px;
  color: #000000;
  text-align: center;
}
.beat_your_competetion p {
  font: normal normal 400 13px/20px sans-serif;
  letter-spacing: 0px;
  color: #000000;
  text-align: center;
  margin: -29px auto 0;
  width: 594px;
}
.review_section {
  margin-bottom: 112px;
}
.industry_section .card h3,
.people_section_problem .card a {
  font: normal normal 400 22px/43px sans-serif;
  letter-spacing: 0px;
  color: #000000;
  text-align: left;
}
.industry_section .card a,
.people_section_problem .card a {
  font: normal normal 400 20px/24px sans-serif;
  letter-spacing: 0px;
  color: var(--greenColor);
  text-align: left;
}
.industry_section .card img,
.people_section_problem .card img {
  height: 57px;
}
.repeat_section h6 {
  font: normal normal 500 47px/53px sans-serif;
  letter-spacing: 0px;
  color: #000000;
  text-align: center;
  width: 551px;
  margin: 0 auto;
}
.repeat_section p {
  font: normal normal 400 14px/25px sans-serif;
  letter-spacing: 0px;
  color: #5b5b5b;
  text-align: center;
  width: 622px;
  margin: 21px auto;
}
.review_section img {
  width: 100%;
  height: 679px;
}
.review_section h5 {
  font: normal normal 600 47px/53px sans-serif;
  letter-spacing: 0px;
  color: #000000;
  text-align: left;
}
.review_section ul li {
  font: normal normal normal 15px/38px sans-serif;
  letter-spacing: 0px;
  color: #505050;
  text-align: left;
}
.video-icon {
  width: auto;
  height: 487px;
  cursor: pointer;
  display: flex;
  margin: 40px auto 79px;
}
.auto {
  font: normal normal 400 16px/25px sans-serif !important;
  letter-spacing: 0px;
  color: #5b5b5b;
  text-align: left;
  width: 389px !important;
  margin: 0 !important;
}
.review_section ul {
  padding: 0 0 0 18px;
}
.review_section .features {
  font: normal normal normal 13px/29px sans-serif;
  letter-spacing: 0px;
  color: #c5864b;
  background: #fef4e6;
  border: 1px solid #ebbd95;
  text-align: left;
  border-radius: 20px;
  padding: 0px 20px;
  margin: 0 0 22px 0px;
}
.started_button .get {
  font: normal normal 500 14px/30px sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  background: var(--greenColor);
  padding: 8px 36px;
  border: none;
  border-radius: 8px;
}
.started_button .learn {
  font: normal normal 400 17px/24px sans-serif;
  letter-spacing: 0px;
  color: var(--greenColor);
  text-align: left;
  background: none;
  border: none;
}
.section_secure {
  background: #dbf1e982;
  opacity: 1;
  padding: 25px 54px 0px 51px;
  border-radius: 20px;
  border: 1px solid gainsboro;
}
.leads_today h4 {
  font: normal normal 600 23px/30px sans-serif;
  letter-spacing: 0px;
  color: #000000;
}
.leads_today p {
  font: normal normal 300 14px/30px sans-serif;
  letter-spacing: 0px;
  color: #4c4c4c;
}
.today_section_images img {
  width: 100%;
  height: 205px;
}
.brings_main_section {
  background: #f3f6f4;
  padding: 0px 17px 0px 59px;
}
.brings_view h5 {
  font: normal normal 600 36px/45px sans-serif;
  letter-spacing: 0px;
  color: #000000;
  width: 545px;
}
.brings_view p {
  font: normal normal 400 16px/24px sans-serif;
  letter-spacing: 0px;
  color: #484848;
  width: 444px;
}
.brings_view h6 {
  font: normal normal 700 17px/30px sans-serif;
  letter-spacing: 0px;
  color: #000000;
}
.brings_view {
  padding: 7% 0 0 0;
}
.arrow-img {
  width: auto;
  margin: -57px 0 0 0;
}
.asked button {
  font: normal normal normal 13px/29px sans-serif;
  letter-spacing: 0px;
  color: #c5864b;
  background: #fef4e6;
  border: 1px solid #ebbd95;
  text-align: left;
  border-radius: 20px;
  padding: 0px 20px;
  margin: 0 0 22px 0px;
  display: flex;
  margin: 79px auto 18px;
}
.asked h5 {
  font: normal normal 600 40px/45px sans-serif;
  letter-spacing: 0px;
  color: #000000;
  text-align: center;
  margin: 23px 0 33px 0;
}
.footer_main {
  background: #fffaf3;
  padding: 56px 59px;
}
.titl_section p {
  font: normal normal 600 15px/26px sans-serif;
  letter-spacing: 0px;
  color: #000000;
  margin: 0px 0 15px 0;
  width: max-content;
}
.titl_section ul li {
  font: normal normal 100 16px/26px sans-serif;
  letter-spacing: 0px;
  color: #000000;
  list-style: none;
  margin: 12px 0;
}
.titl_section ul {
  padding: 0;
}
.subscribe_section_footer p {
  font: normal normal 100 16px/26px sans-serif;
  letter-spacing: 0px;
  color: #545454;

  margin: 12px 0;
}
.subscribe_section_footer span {
  font: normal normal 500 16px/26px sans-serif;
  letter-spacing: 0px;
  color: #000000;
}
.footer_bottom_section {
  border-top: 1px solid #e3e3e3;
}
.border_padding {
  padding: 19px 0 0 0;
}
.services_footer ul li {
  margin: 12px -2px 0px 37px;
  font: normal normal 100 16px/26px sans-serif;
  letter-spacing: 0px;
  color: #545454;
}
.services_footer ul li:first-child {
  list-style: none;
}
/*

====================================================================
                    reviewa-css-end
====================================================================
*/
/*

====================================================================
                    reviewa-css-end
====================================================================
*/
.see_pricing button {
  color: var(--greenColor);
  border: 1px solid var(--greenColor);
  background: white;
  padding: 10px 27px;
  border-radius: 8px;
  font: normal normal 500 16px/26px sans-serif;
  letter-spacing: 0px;
}
.people_section_problem h3 {
  font: normal normal 600 34px/36px sans-serif;
  letter-spacing: 0px;
  color: #000000;
  text-align: center;
  margin: 17px auto 8px 0;
}
.counter_section p {
  font: normal normal 600 21px/16px sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  text-align: center;
}
.counter_section {
  background: #e29a57;
  padding: 19px;
  border-radius: 50%;
  width: 55px;
  height: 55px;
}
.counter_text_section h3 {
  font: normal normal 600 21px/32px sans-serif;
  letter-spacing: 0px;
  color: #000000;
  text-align: left;
}
.counter_text_section p {
  font: normal normal 400 13px/8px sans-serif;
  letter-spacing: 0px;
  color: #808080;
  text-align: center;
}
.counter_section span {
  border: 1px dashed #e0e3e7;
  position: absolute;
  width: 77px;
  height: 0px;
  transform: rotate(89deg);
  margin: 50px 0 0 -31px;
}
.stepper_class .stepper {
  margin: 0 0px 88px 0px;
}
.business_section img {
  width: auto;
  height: 205px;
}
.leads_today p {
  font: normal normal 300 14px/23px sans-serif !important;
  color: #4e4e4e !important;
  width: 618px !important;
}
.main_block_price h5 {
  font: normal normal 700 60px/77px sans-serif;
  letter-spacing: 0px;
  color: #000000;
  width: 893px;
  margin: 33px auto;
  text-align: center;
}
/*

====================================================================
                    reviewa-css-end
====================================================================
*/
@media screen and (max-width: 768px) {
  .button_push_section h5 {
    font: normal normal 700 39px/46px sans-serif;
  }
  .button_push_section h5 span {
    font: normal normal 300 39px/27px sans-serif;
  }
  .button_push_section h5 {
    width: auto;
  }
  .form_Section input {
    width: 244px;
  }
  /* .submit_section button {
    margin-left: 12px;
  } */
  .button_push_section {
    padding: 14% 0 14% 0;
  }
  .find_your_industry_section {
    padding: 15px 0;
  }
  .industry_section h4 {
    font: normal normal 600 32px/24px sans-serif;

    margin: 23px 0;
  }
  .industry_section .row {
    margin: 0 auto;
    display: table;
  }
  .repeat_section h6 {
    font: normal normal 500 25px/38px sans-serif;

    width: auto;
    margin: 0 auto;
  }
  .win_every_section {
    padding: 34px 0 0 0;
  }
  .navbar-light .navbar-nav .nav-link,
  .sign-in a {
    text-align: center;
  }
  .repeat_section p {
    font: normal normal 400 14px/23px sans-serif;

    width: auto;
    margin: 13px auto;
  }
  .review_section {
    margin-bottom: 50px;
  }
  .review_section h5 {
    font: normal normal 600 36px/40px sans-serif;
    margin: 58px 0 10px 0;
  }
  .review_section ul li {
    font: normal normal normal 14px/31px sans-serif;
  }
  .review_section .features {
    margin: 30px 0 22px 0px;
  }
  .section_secure {
    padding: 31px 6px 0px 29px;

    margin: 0 0 37px 0;
  }
  .value-img {
    width: 100% !important;
    height: auto !important;
    margin: 18px 0 0 0;
  }
  .today_section_images img {
    width: 100%;
    height: auto;
    margin: 31px 0 0 0;
  }

  .leads_today p {
    font: normal normal 300 14px/19px sans-serif !important;
    color: #4e4e4e !important;
    width: auto !important;
  }
  .brings_main_section {
    background: #f3f6f4;
    padding: 0px 17px 0px 22px;
    text-align: center;
  }
  .review_section h2 {
    font: normal normal 600 26px/38px sans-serif !important;
    margin: 36px 0 14px 0 !important;
  }
  .arrow-img {
    display: none;
  }
  .moblie_mockup img:first-child {
    display: none;
  }
  .auto {
    width: auto !important;
  }
  .brings_view h5 {
    font: normal normal 600 26px/38px sans-serif;

    width: auto;
  }
  .card-header .title {
    font: normal normal 600 11px/26px sans-serif;
    letter-spacing: 0px;
    color: #000000;
  }
  .people_section_problem h4,
  .beat_your_competetion h4 {
    font: normal normal 600 24px/36px sans-serif;
    margin: 10px auto 38px;
    width: auto;
  }
  .people_section_problem .card {
    border-radius: 25px;
    width: 284px !important;
    margin: 14px auto;
  }
  .beat_your_competetion p {
    margin: -27px auto 23px;
    width: auto;
  }
  .stepper_class .stepper {
    margin: 8px 0px 47px 0px;
    padding: 34px 18px 4px 18px;
  }

  .counter_text_section h3 {
    font: normal normal 600 16px/32px sans-serif;
  }
  .counter_text_section p {
    font: normal normal 400 13px/18px sans-serif;
    letter-spacing: 0px;
    color: #808080;
    text-align: left;
  }
  .counter_section {
    background: #e29a57;
    padding: 17px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
  .counter_section span {
    border: 1px dashed #e0e3e7;
    position: absolute;
    width: 77px;
    height: 0px;
    transform: rotate(89deg);
    margin: 57px 0 0 -34px;
  }

  .people_section_problem .card-body {
    padding: 24px 8px;
  }
  .type_img img {
    width: 100%;
    height: auto;
    margin: -22px 0 34px 3px !important;
  }
  .main_block_price h5 {
    font: normal normal 700 33px/48px sans-serif;

    text-align: center;
    padding: 10px;
  }
}
